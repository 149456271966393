<!--
 * @Author: DY
 * @Date: 2020-05-19 15:49:08
 * @LastEditTime: 2023-09-25 14:58:16
 * @LastEditors: Please set LastEditors
 * @Description: 经营驾驶舱页面
 * @FilePath: \BusinessPlatform\src\views\leadingCockpit\index.vue
-->
<template>
    <div class="leadingCockpit">
        <back></back>
        <header>
            <span @click="centerDialogVisible = true">经营驾驶舱</span>
        </header>
        <section>
            <div class="contant_l">
                <div class="number">
                    <div class="chartHead">
                    </div>
                    <div class="total">
                        <div>
                            <h4>本月销量(万方)：</h4>
                            <p class="scrollNumbers scrollNumbers1" id="monthlySales"></p>
                        </div>
                        <div>
                            <h4>年累计销量(万方)：</h4>
                            <p class="scrollNumbers scrollNumbers2" id="cumulativeSales"></p>
                        </div>
                    </div>
                    <div class="chart" id="numberChart"></div>
                </div>
                <div class="price">
                    <div class="chartHead">
                    </div>
                    <div class="total">
                        <div>
                            <h4>本月售价(元/方)：</h4>
                            <p class="scrollNumbers scrollNumbers1" id="monthlySellingPrice"></p>
                        </div>
                        <div>
                            <h4>本年度均价(元/方)：</h4>
                            <p class="scrollNumbers scrollNumbers2" id="annualSveragePrice"></p>
                        </div>
                    </div>
                    <div class="chart" id="priceChart"></div>
                </div>
            </div>
            <div class="contant_c">
                <div class="map">
                    <div class="chart" id="mapChart"></div>
                </div>
                <div class="account">
                    <div class="chartHead">
                        <div class="total-wrapper">
                            <div>
                                <h4>累计主收(万元)：</h4>
                                <p>{{ amoumtBlock.receivables }}</p>
                            </div>
                            <div>
                                <h4>累计实收账款(万元)：</h4>
                                <p>{{ amoumtBlock.accounts_received }}</p>
                            </div>
                            <div @click="receivableBalancePop">
                                <h4>应收账款余额(万元)：</h4>
                                <p>{{ amoumtBlock.receivable_balance }}</p>
                            </div>
                            <div>
                                <h4>货款回收率：</h4>
                                <p>{{ amoumtBlock.rate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="chart-con">
                        <div class="chart" id="receivableChart"></div>
                        <!--                        <div class="chart" id="copeWithChart"></div>-->
                    </div>
                </div>
            </div>
            <div class="contant_r">
                <div class="principal">
                    <div class="chartHead">
                    </div>
                    <div class="total">
                        <div>
                            <h4>本月单方成本(元/方)：</h4>
                            <p class="scrollNumbers scrollNumbers1" id="monthlyCost"></p>
                        </div>
                        <div>
                            <h4>年单方成本(元/方)：</h4>
                            <p class="scrollNumbers scrollNumbers2" id="annualAccumulation"></p>
                        </div>
                    </div>
                    <div class="chart" id="principalChart"></div>
                </div>
                <div class="profit">
                    <div class="chartHead"></div>
                    <div class="total">
                        <div>
                            <h4>本月毛利额(元/方)：</h4>
                            <p class="scrollNumbers scrollNumbers1" id="monthlyGrossProfitRate"></p>
                        </div>
                        <div>
                            <h4>年度毛利额(元/方)：</h4>
                            <p class="scrollNumbers scrollNumbers2" id="annualAveragePrice"></p>
                        </div>
                    </div>
                    <div class="chart" id="profitChart"></div>
                </div>
            </div>
        </section>
        <el-dialog
            title="按标号下钻"
            :visible.sync="centerDialogVisible"
            center
        >
            <i class="icon"></i>
            <div class="table">
                <table>
                    <tbody>
                        <tr>
                            <th>C15</th>
                            <th>C20</th>
                            <th>C25</th>
                            <th>C30</th>
                            <th>C35</th>
                            <th>C40</th>
                            <th>C45</th>
                            <th>C50</th>
                            <th>C55</th>
                        </tr>
                        <tr>
                            <td>20px</td>
                            <td>1.35</td>
                            <td>0.80</td>
                            <td>8.14</td>
                            <td>4.72</td>
                            <td>0.84</td>
                            <td>0.43</td>
                            <td>0.25</td>
                            <td>0.01</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="charts" id="ejectCharts"></div>
        </el-dialog>
    </div>
</template>

<script>
import './CSS/leadingCockpit.styl';
import back from './../EBCHome/back';
import {
    lineColumn,
} from '@/assets/js/charts';
import { DigitRoll } from '@/assets/js/charts/digitRoll.js';

export default {
    // 领导驾驶舱
    name: 'leading-cockpit',
    components: { back },
    props: {},
    data() {
        return {
            centerDialogVisible: false,
            // 款-块
            amoumtBlock: {},
            // 地图悬浮数据
            mapData: {},
            // 企业数据列表
            tenantList: [],

            // 企业应收 amount
            tenantAmount: {},

            // 每月销量最大值
            maxQuantity: 0,
        };
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {
        this.initData();
    },
    destroyed() { },
    methods: {
        async initData() {
            // 量
            this.handleQuantity();
            // 价
            this.handlePrice();
            // 款
            this.handelAmoumtBlock();
            this.handleReceivable();
            // this.handeAmountBalance();
            // 本
            this.handleCost();
            this.handleProfit();
            // 地图
            await this.getTenantList();
            this.drawMap('mapChart');
        },
        async getTenantList() {
            const data = await this.getEnterpriceList();
            const sales = await this.getEnterpriceSales();
            let maxQuantity = 0;
            data.forEach(item => {
                const quantity = sales.find(sale => sale.zu_h_id === item.zu_h_id);
                item.quantity = quantity || Math.round(Math.random() * 10000);
                if (item.quantity > maxQuantity) {
                    maxQuantity = item.quantity;
                }
            });
            this.maxQuantity = maxQuantity;
            this.tenantList = data || [];
        },
        // 应收账款余额钻取
        receivableBalancePop() {
            this.$toast({
                title: false,
                type: 'eject',
                width: '12rem',
                t_url: 'leadingCockpit/components/accountsReceivableDrill/areaDrill',
                viewPosition: 'view',
                extr: {
                    title: '淮海运营区',
                    pian_q_id: '-',
                },
            });
        },
        // 量钻取
        accountDrill(month) {
            const monIndex = month.indexOf('月');
            const mon = month.substr(0, monIndex);
            this.$toast({
                title: false,
                type: 'eject',
                width: '12rem',
                t_url: 'leadingCockpit/components/accountDataDrill/index',
                viewPosition: 'view',
                extr: {
                    month: Number(mon),
                    pian_q_id: '-',
                    qi_y_id: '-',
                    title: '淮海运营区',
                },
            });
        },
        // 处理量数据
        async handleQuantity() {
            const res = await this.getQuantity();
            if (res) {
                // 月销量
                new DigitRoll({
                    container: '#monthlySales',
                }).roll(res.quantity_month);
                // 累计销量
                new DigitRoll({
                    container: '#cumulativeSales',
                }).roll(res.quantity_year);

                const month = [];
                for (let i = 1; i < res.current_month + 1; i++) {
                    month.push(i + '月');
                }
                const currentYearData = [];
                const preYearData = [];
                month.map(() => {
                    currentYearData.push(0);
                    preYearData.push(0);
                });
                res.quantity.map(item => {
                    if (item.month_dim <= res.current_month) {
                        if (item.is_bn === 1) {
                            currentYearData[item.month_dim - 1] = item.quantity;
                        } else {
                            preYearData[item.month_dim - 1] = item.quantity;
                        }
                    }
                });
                const data = {
                    xAxis: { data: month },
                    yAxis: {
                        name: '单位(万方)',
                        nameTextStyle: '',
                    },
                    series: [
                        {
                            name: res.current_year - 1 + '年每月销量',
                            barWidth: 15,
                            data: preYearData,
                            color: ['#2F80F2', '#24CCFC'],
                        },
                        {
                            name: res.current_year + '年每月销量',
                            barWidth: 15,
                            data: currentYearData,
                            color: ['#FEBE2A', '#FCF020'],
                        },
                    ],
                };
                this.drawBar('numberChart', data);
            }
        },
        // 标准柱图
        drawBar(value, data) {
            const drawBarChart = window.$globalHub.$echarts.init(document.getElementById(value));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                        },
                    },
                },
                legend: {
                    textStyle: {
                        color: '#fff',
                    },
                    right: 10,
                },
                grid: {
                    left: 40,
                    top: 30,
                    right: 20,
                    bottom: 20,
                },
                calculable: true,
                xAxis: [{
                    type: 'category',
                    data: [],
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.5)',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                yAxis: [],
                series: [],
            };
            if (data) {
                const series = [];
                option.xAxis[0].data = data.xAxis.data;
                option.yAxis.push({
                    name: data.yAxis.name,
                    nameTextStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        padding: data.yAxis.nameTextStyle,
                    },
                    yAxisIndex: 1,
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.1)',
                            width: 0.5,
                            type: 'dashed',
                        },
                    },
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    axisLabel: {
                        show: true,
                        color: 'rgba(255,255,255,0.5)',
                    },
                    axisTick: {
                        show: false,
                    },
                });
                data.series.forEach(item => {
                    series.push({
                        name: item.name,
                        type: 'bar',
                        barMaxWidth: item.barWidth,
                        data: item.data,
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [{
                                        offset: 0,
                                        color: item.color[0],
                                    },
                                    {
                                        offset: 1,
                                        color: item.color[1],
                                    },
                                    ]
                                ),
                            },
                        },
                    });
                });
                option.series = series;
            }
            drawBarChart.setOption(option);
            drawBarChart.off('click');
            drawBarChart.on('click', param => {
                this.accountDrill(param.name);
            });

        },
        // 处理价数据
        async handlePrice() {
            const res = await this.getPrice();
            if (res) {
                // 本月售价
                new DigitRoll({
                    container: '#monthlySellingPrice',
                }).roll(res.price_month);

                // 年度均售价
                new DigitRoll({
                    container: '#annualSveragePrice',
                }).roll(res.price_year);

                const month = [];
                for (let i = 1; i < res.current_month + 1; i++) {
                    month.push(i + '月');
                }
                const currentYearData = [];
                const preYearData = [];
                const currentYearAveragePrice = [];
                const preYearAveragePrice = [];
                month.map(() => {
                    currentYearData.push(0);
                    preYearData.push(0);
                    currentYearAveragePrice.push(res.price_year);
                    preYearAveragePrice.push(res.price_prev_year);
                });
                res.prices.map(item => {
                    if (item.month_dim <= res.current_month) {
                        if (item.is_bn === 1) {
                            currentYearData[item.month_dim - 1] = item.price;
                        } else {
                            preYearData[item.month_dim - 1] = item.price;
                        }
                    }
                });

                const data = {
                    xAxis: { data: month },
                    yAxis: [
                        {
                            name: '单位(元/方)',
                            nameTextStyle: '',
                        },
                        {
                            name: '',
                            nameTextStyle: '',
                            show: 'false',
                        },
                    ],
                    series: [
                        {
                            type: 'bar',
                            name: res.current_year - 1 + '年每月价格',
                            barWidth: 15,
                            data: preYearData,
                            color: ['#00B59B', '#7BE9CB'],
                        },
                        {
                            type: 'bar',
                            name: res.current_year + '年每月价格',
                            barWidth: 15,
                            data: currentYearData,
                            color: ['#FEBE2A', '#FCF020'],
                        },
                        {
                            type: 'line',
                            name: res.current_year - 1 + '年均价',
                            data: preYearAveragePrice,
                            color: ['#77D563'],
                        },
                        {
                            type: 'line',
                            name: res.current_year + '年均价',
                            data: currentYearAveragePrice,
                            color: ['#26BFF9'],
                        },
                    ],
                };
                lineColumn('priceChart', data);
            }
        },
        // 款-块
        async handelAmoumtBlock() {
            const res = await this.getAmoumtBlock();
            if (res) {
                this.amoumtBlock = res;
            }
        },
        // 款-应收、实收
        async handleReceivable() {
            const res = await this.getReceivable();
            if (res) {
                const xData = [];
                const receivables_list = [];
                const accounts_received_list = [];
                const receivable_balance_list = [];
                const rate_list = [];
                res.receivables_list.map(item => {
                    if (item.tenant_id) {
                        receivables_list.push(item.amount);
                        xData.push(item.tenant_name);
                    }
                });
                res.accounts_received_list.map(item => {
                    if (item.tenant_id) {
                        accounts_received_list.push(item.amount);
                    }
                });
                res.receivable_balance_list.map(item => {
                    if (item.tenant_id) {
                        receivable_balance_list.push(item.amount);
                    }
                    this.tenantAmount[item.tenant_id] = item.amount;
                });
                res.rate_list.map(item => {
                    if (item.tenant_id) {
                        rate_list.push(item.rate);
                    }
                });
                const data = {
                    xAxis: { data: xData },
                    receivables_list: receivables_list,
                    accounts_received_list: accounts_received_list,
                    receivable_balance_list: receivable_balance_list,
                    rate_list: rate_list,
                };
                this.lineColumn('receivableChart', data);


            }
        },
        // 折线柱图
        lineColumn(obj, data) {
            const lineColumnChart = window.$globalHub.$echarts.init(document.getElementById(obj));
            const option = {
                title: {
                    text: '',
                    x: 'center',
                    y: 0,
                    textStyle: {
                        color: '#B4B4B4',
                        fontSize: 16,
                        fontWeight: 'normal',
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                        },
                    },
                },
                legend: {
                    textStyle: {
                        color: '#fff',
                    },
                    top: 0,
                    right: 10,
                },
                grid: {
                    left: 60,
                    top: 50,
                    right: 60,
                    bottom: 40,
                },
                xAxis: [{
                    // type:'category',
                    data: [],
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.5)',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                yAxis: [
                    {
                        name: '单位(万元)',
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            padding: '',
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    {
                        name: '单位(%)',
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            padding: '',
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '累计应收',
                        barWidth: 15,
                        type: 'bar',
                        barGap: '-100%', // 重疊
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#CD63F9',
                                },
                                {
                                    offset: 1,
                                    color: '#9759D5',
                                },
                                ]),
                            },
                        },
                        data: data.receivables_list,
                    },
                    {
                        name: '累计实收',
                        type: 'bar',
                        barWidth: 15,
                        yAxisIndex: 0,
                        stack: '累计应收',
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#AED563',
                                },
                                {
                                    offset: 1,
                                    color: '#78D563',
                                },
                                ]),
                            },
                        },
                        data: data.accounts_received_list,
                    },
                    {
                        name: '应收账款余额',
                        type: 'bar',
                        barWidth: 15,
                        yAxisIndex: 0,
                        stack: '累计应收',
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#24CCFC',
                                },
                                {
                                    offset: 1,
                                    color: '#2F80F2',
                                },
                                ]),
                            },
                        },
                        data: data.receivable_balance_list,
                    },
                    {
                        name: '货款回收率',
                        type: 'line',
                        // smooth: true,
                        showSymbol: true,
                        symbolSize: 8,
                        lineStyle: {
                            normal: {
                                color: '#FC9343',
                            },
                        },
                        yAxisIndex: 1,
                        label: {
                            show: false,
                            position: 'top',
                            textStyle: {
                                color: '#FC9343',
                            },
                        },
                        itemStyle: {
                            color: '#6c50f3',
                            borderColor: '#fff',
                            borderWidth: 1,
                            shadowColor: 'rgba(0, 0, 0, .3)',
                            shadowBlur: 0,
                            shadowOffsetY: 2,
                            shadowOffsetX: 2,
                            normal: {
                                color: '#FC9343',
                                lineStyle: {
                                    color: '#FC9343',
                                },
                            },
                        },
                        data: data.rate_list,
                    },
                ],
            };
            option.xAxis[0].data = data.xAxis.data;
            lineColumnChart.setOption(option);
        },
        // 款-应收账款余额
        async handeAmountBalance() {
            const res = await this.getAmountBalance();
            if (res) {
                const amount_balance_list = [];
                const amount_balance_rate = [];
                res.category_list.map(item => {
                    const arr1 = res.amount_balance_list.filter(v => v.category === item);
                    if (arr1.length > 0) {
                        amount_balance_list.push(arr1[0].balance);
                    } else {
                        amount_balance_list.push(0);
                    }
                    const arr2 = res.amount_balance_rate.filter(v => v.category === item);
                    if (arr2.length > 0) {
                        amount_balance_rate.push(arr2[0].rate);
                    } else {
                        amount_balance_rate.push(0);
                    }
                });
                const data = {
                    xAxis: { data: res.category_list },
                    yAxis: [
                        {
                            name: '单位(万元)',
                            nameTextStyle: '',
                        },
                        {
                            name: '单位(%)',
                            nameTextStyle: '',
                        },
                    ],
                    series: [
                        {
                            type: 'bar',
                            name: '应收账款余额',
                            barWidth: 15,
                            data: amount_balance_list,
                            color: ['#24CCFC', '#2F80F2'],
                            yAxisIndex: 0,
                        },
                        {
                            type: 'line',
                            name: '货款回收率',
                            data: amount_balance_rate,
                            color: ['#FC9343'],
                            yAxisIndex: 1,
                        },
                    ],
                };
                lineColumn('copeWithChart', data);
            }
        },
        // 获取本
        async handleCost() {
            const res = await this.getCost();
            if (res) {
                // 本月成本
                new DigitRoll({
                    container: '#monthlyCost',
                }).roll(res.month_single_volume_cost);

                // 年单方成本
                new DigitRoll({
                    container: '#annualAccumulation',
                }).roll(res.year_single_volume_cost);
                const date = new Date();
                const year = date.getFullYear();
                const mon = date.getMonth() + 1;
                const month = [];
                const prevyear_single_volume_cost = [];
                const single_volume_cost = [];
                for (let i = 1; i < mon + 1; i++) {
                    month.push(i + '月');
                    prevyear_single_volume_cost.push(0);
                    single_volume_cost.push(0);
                }
                res.prevyear_single_volume_cost.map(item => {
                    if (item.month_dim <= mon) {
                        prevyear_single_volume_cost[item.month_dim - 1] = item.single_volume_cost;
                    }
                });
                res.single_volume_cost.map(item => {
                    if (item.month_dim <= mon) {
                        single_volume_cost[item.month_dim - 1] = item.single_volume_cost;
                    }
                });
                const data = {
                    xAxis: { data: month },
                    yAxis: [
                        {
                            name: '单位(元/方)',
                            nameTextStyle: '',
                        },
                    ],
                    series: [
                        {
                            type: 'bar',
                            name: year - 1 + '每月单方成本',
                            barWidth: 15,
                            data: prevyear_single_volume_cost,
                            color: ['#ADD563', '#79D563'],
                        },
                        {
                            type: 'bar',
                            name: year + '每月单方成本',
                            barWidth: 15,
                            data: single_volume_cost,
                            color: ['#24CCFC', '#2F80F2'],
                        },
                    ],
                };
                lineColumn('principalChart', data);
            }
        },
        // 获取利
        async handleProfit() {
            const res = await this.getProfit();
            if (res) {
                // 本月毛利率
                new DigitRoll({
                    container: '#monthlyGrossProfitRate',
                }).roll(res.month_gross_profit);
                // 本年度均价
                new DigitRoll({
                    container: '#annualAveragePrice',
                }).roll(res.year_gross_profit);
                const date = new Date();
                const mon = date.getMonth() + 1;
                const month = [];
                const gross_profits = [];
                const profits = [];
                for (let i = 1; i < mon + 1; i++) {
                    month.push(i + '月');
                    gross_profits.push(0);
                    profits.push(0);
                }
                res.gross_profits.map(item => {
                    if (item.month_dim <= mon) {
                        gross_profits[item.month_dim - 1] = item.gross_profit;
                    }
                });
                res.profits.map(item => {
                    if (item.month_dim <= mon) {
                        profits[item.month_dim - 1] = item.profit;
                    }
                });
                const data = {
                    xAxis: { data: month },
                    yAxis: [
                        {
                            name: '单位(万元)',
                            nameTextStyle: '',
                        },
                        {
                            name: '单位(元/方)',
                            nameTextStyle: '',
                        },
                    ],
                    series: [
                        {
                            type: 'bar',
                            name: '每月利润',
                            barWidth: 15,
                            data: profits,
                            color: ['#24CCFC', '#2F80F2'],
                        },
                        {
                            type: 'line',
                            name: '每月毛利额',
                            data: gross_profits,
                            color: ['#FECD28'],
                        },
                    ],
                };
                lineColumn('profitChart', data);
            }
        },
        // 处理企业数据
        async handleEnterprice(tenant_id) {
            const tenantData = await this.getEnterprice(tenant_id);
            tenantData.amount_balance = this.tenantAmount[tenant_id] || 0;
            this.mapData[tenant_id] = tenantData;
        },
        // 量
        getQuantity() {
            const path = '/interfaceApi/report/leadercockpit/quantity/1';
            return this.$axios.$get(path, { defEx: true });
        },
        // 价
        getPrice() {
            const path = '/interfaceApi/report/leadercockpit/price';
            return this.$axios.$get(path, { defEx: true });
        },
        // 款-块
        getAmoumtBlock() {
            const path = '/interfaceApi/report/leadercockpit/amountblock/1';
            return this.$axios.$get(path, { defEx: true });
        },
        // 款-应收、实收
        getReceivable() {
            const path = '/interfaceApi/report/leadercockpit/amountreceivablereceived/1';
            return this.$axios.$get(path, { defEx: true });
        },
        // 款-应收账款余额
        getAmountBalance() {
            const path = '/interfaceApi/report/leadercockpit/amountbalance/1';
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取本
        getCost() {
            const path = '/interfaceApi/report/leadercockpit/cost';
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取利
        getProfit() {
            const path = '/interfaceApi/report/leadercockpit/profit';
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取企业数据
        getEnterprice(tenant_id) {
            const path = '/interfaceApi/report/leadercockpit/enterprise/' + tenant_id;
            return this.$axios.$get(path, { defEx: true });
        },

        // 获取上线企业列表
        getEnterpriceList(org_id = '') {
            const path = '/interfaceApi/report/leadercockpit/getorgdata?org_id=' + org_id;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取企业 本月销量
        getEnterpriceSales(org_id = '') {
            const path = '/interfaceApi/report/leadercockpit/orgsalesdata?org_id=' + org_id;
            return this.$axios.$get(path, { defEx: true });
        },
        // 地图
        async drawMap(value) {
            const that = this;
            $.get('./../../mapJson/china.json', function (geoJson) {
                window.$globalHub.$echarts.registerMap('china', geoJson);
                const drawMapChart = window.$globalHub.$echarts.init(document.getElementById(value));

                const geoCoordMap = {};
                const data = [];
                that.tenantList.forEach(item => {
                    geoCoordMap[item.zu_h_id] = [item.x_coordinate_point, item.y_coordinate_point];
                    const org_shortname = item.org_shortname || item.org_name;
                    const shortName = org_shortname.replace(/商混|中联/, '');
                    data.push({
                        tenant_id: item.zu_h_id,
                        name: org_shortname,
                        transAmt: shortName,
                        serviceRate: 99,
                        is_relocation: item.is_relocation,
                        quantity: item.quantity,
                    });
                });

                const convertData = function (data) {
                    const res = [];
                    for (let i = 0; i < data.length; i++) {
                        const geoCoord = geoCoordMap[data[i].tenant_id];
                        if (geoCoord) {
                            res.push({
                                tenant_id: data[i].tenant_id,
                                name: data[i].name,
                                value: geoCoord.concat(data[i].transAmt),
                                is_relocation: data[i].is_relocation,
                                quantity: data[i].quantity,
                            });
                        }
                    }
                    return res;
                };
                const pos = that.getPosCenter(that.tenantList);
                const centerx = pos.center[0];
                const centery = pos.center[1];
                const zoom = that.getZoom(pos.min[0], pos.min[1], pos.max[0], pos.max[1]);
                const mapOption = {
                    // 鼠标提示框
                    tooltip: {
                        trigger: 'item',
                        triggerOn: 'click',
                        showDelay: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        transitionDuration: 0.2,
                        // eslint-disable-next-line consistent-return
                        formatter: function (params, ticket, callback) {
                            if (params.componentSubType === 'scatter') {
                                if (!that.mapData[ params.data.tenant_id]) {
                                    that.handleEnterprice(params.data.tenant_id).then(() => {
                                        callback(ticket, that.toHtml(params));
                                    });
                                } else {
                                    return that.toHtml(params);
                                }
                                return '加载中...';
                            }
                        },
                    },
                    visualMap: {
                        show: false,
                        left: 'right',
                        min: 100,
                        max: 3800,
                        inRange: {
                            color: ['#016ABB', '#014FB1', '#2536AA', '#0055B0'],
                        },
                        text: ['High', 'Low'], // 文本，默认为数值文本
                        calculable: true,
                        seriesIndex: [0],
                    },
                    geo: {
                        name: '1',
                        type: 'map',
                        map: 'china', // 类型,与上文中注册地图的名称一致
                        zoom: zoom, // 当前视角的缩放比例
                        roam: true, // 是否开启平游或缩放
                        label: {
                            normal: {
                                show: true,
                                color: '#04B3F4', // 每个区域文字颜色
                                fontSize: '16px',
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        mapLocation: {
                            y: 60,
                        },
                        itemStyle: {
                            emphasis: {
                                label: {
                                    show: false,
                                },
                            },
                            normal: {
                                label: {
                                    show: !0,
                                },
                                formatter: function formatter(a) {
                                    return a.value;
                                },
                                borderColor: '#063E71', // 每个区域的边框色
                                areaColor: '#0d1c31', // 区域背景色
                            },

                        },
                        center: [centerx, centery],
                    },
                    series: [{
                        type: 'map',
                        map: 'china',
                        geoIndex: 0,
                        aspectScale: 0.75, // 长宽比
                        showLegendSymbol: false, // 存在legend时显示
                        label: {
                            normal: {
                                show: true,
                                color: '#04B3F4', // 每个区域文字颜色
                                fontSize: '16px',
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        mapLocation: {
                            y: 60,
                        },
                        itemStyle: {
                            emphasis: {
                                label: {
                                    show: false,
                                },
                            },
                            normal: {
                                label: {
                                    show: !0,
                                },
                                formatter: function formatter(a) {
                                    return a.value;
                                },
                                borderColor: '#063E71', // 每个区域的边框色
                                areaColor: '#0d1c31', // 区域背景色
                            },

                        },
                        animation: false,
                        data: [
                            {
                                name: '北京',
                                value: 125,
                            },
                            {
                                name: '河北',
                                value: 125,
                            },
                            {
                                name: '山东',
                                value: 125,
                            },
                            {
                                name: '河南',
                                value: 125,
                            },
                            {
                                name: '安徽',
                                value: 125,
                            },
                            {
                                name: '江苏',
                                value: 125,
                            },
                        ],
                    }, {

                        symbolSize: 5,
                        label: {
                            normal: {
                                formatter: '{b}',
                                position: 'right',
                                show: false,
                            },
                            emphasis: {
                                show: true,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: '#fff',
                            },
                        },
                        name: 'light',
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        data: convertData(data),

                    }, {
                        type: 'map',
                        map: 'china',
                        geoIndex: 0,
                        aspectScale: 0.75, // 长宽比
                        showLegendSymbol: false, // 存在legend时显示
                        label: {
                            normal: {
                                show: false,
                            },
                            emphasis: {
                                show: false,
                                textStyle: {
                                    color: '#fff',
                                },
                            },
                        },
                        roam: true,
                        itemStyle: {
                            normal: {
                                areaColor: '#031525',
                                borderColor: '#FFFFFF',
                            },
                            emphasis: {
                                areaColor: '#2B91B7',
                            },
                        },
                        animation: false,
                        data: data,
                    }, {
                        name: 'Top 5',
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        symbol: 'circle',
                        symbolSize: (val, params) => {
                            let result = 0;
                            const avg = that.maxQuantity / 90;
                            if (avg) {
                                result = params.data.quantity / avg;
                            }
                            if (result < 50) {
                                result = 40 + result / 10;
                            }
                            return result;
                        },
                        label: {
                            normal: {
                                show: true,
                                textStyle: {
                                    color: '#fff',
                                    fontSize: 12,
                                    fontFamily: 'technology',
                                },
                                formatter(params) {
                                    let sufix = '';
                                    const avg = that.maxQuantity / 90;
                                    if (avg) {
                                        const result = params.data.quantity / avg;
                                        if (result > 65) {
                                            sufix = '1';
                                        }
                                        if (result > 80) {
                                            sufix = '2';
                                        }
                                    }
                                    let numCss = 'num1' + sufix;
                                    let nameCss = 'name1' + sufix;
                                    if (params.data.is_relocation === 1) {
                                        numCss = 'num2' + sufix;
                                        nameCss = 'name2' + sufix;
                                    }
                                    return '{' + numCss + '|' + params.data.quantity + '}\n{' + nameCss + '|' + params.data.value[2] + '}';
                                },
                                align: 'center',
                                rich: {
                                    num1: {
                                        color: '#014E25',
                                        fontSize: 18,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    num11: {
                                        color: '#014E25',
                                        fontSize: 30,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    num12: {
                                        color: '#014E25',
                                        fontSize: 36,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    num2: {
                                        color: '#3D3938',
                                        fontSize: 18,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    num21: {
                                        color: '#3D3938',
                                        fontSize: 30,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    num22: {
                                        color: '#3D3938',
                                        fontSize: 36,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    name1: {
                                        color: '#014E25',
                                        fontSize: 11,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    name11: {
                                        color: '#014E25',
                                        fontSize: 14,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    name12: {
                                        color: '#014E25',
                                        fontSize: 18,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    name2: {
                                        color: '#3D3938',
                                        fontSize: 11,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    name21: {
                                        color: '#3D3938',
                                        fontSize: 14,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                    name22: {
                                        color: '#3D3938',
                                        fontSize: 18,
                                        fontFamily: 'QuartzLTStd',
                                    },
                                },
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: params => {
                                    let color = ['#76E0A8', '#13BD63'];
                                    if (params.data.is_relocation === 1) {
                                        color = ['#FCED7A', '#F3BA12'];
                                    }
                                    return new that.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color: color[0], // 0% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: color[1], // 100% 处的颜色
                                    }], false);
                                },
                                opacity: 1,
                            },
                        },
                        data: convertData(data),
                        showEffectOn: 'render',
                        rippleEffect: {
                            brushType: 'stroke',
                        },
                        hoverAnimation: true,
                        zlevel: 1,
                    }],
                };
                drawMapChart.setOption(mapOption);
                drawMapChart.resize();
                drawMapChart.on('mouseover', function (params) {
                    if (params.componentSubType === 'map') {
                        drawMapChart.dispatchAction({
                            type: 'downplay',
                        });
                    }
                });
            });
        },
        // 获取悬浮html
        toHtml(params) {
            const data = this.mapData[params.data.tenant_id];
            let Shtml = '';
            Shtml += '<div class=\'mapTooltip\'>';
            Shtml += '<h4>' + params.name + '</h4>';
            Shtml += '<i></i>';
            Shtml += '<table>';
            Shtml += '<tbody>';
            Shtml += '<tr>';
            Shtml += '<td class="amount">';
            Shtml += '<h5>本月销量(万方)</h5>';
            Shtml += '<p>' + data.month_sale + '</p>';
            Shtml += '</td>';
            Shtml += '<td class="price">';
            Shtml += '<h5>本月营业收入(万元)</h5>';
            Shtml += '<p>' + data.month_income + '</p>';
            Shtml += '</td>';
            Shtml += '<td class="paragraph">';
            Shtml += '<h5>应收账款余额(万元)</h5>';
            Shtml += '<p>' + data.amount_balance + '</p>';
            Shtml += '</td>';
            Shtml += '</tr>';
            Shtml += '<tr>';
            Shtml += '<td class="book">';
            Shtml += '<h5>价格(元/方)</h5>';
            Shtml += '<p>' + data.month_price + '</p>';
            Shtml += '</td>';
            Shtml += '<td class="benefit">';
            Shtml += '<h5>单方成本(元/方)</h5>';
            Shtml += '<p>' + data.month_single_volume_cost + '</p>';
            Shtml += '</td>';
            Shtml += '<td class="paragraph">';
            Shtml += '<h5>毛利额(元/方)</h5>';
            Shtml += '<p>' + data.gross_profit + '</p>';
            Shtml += '</td>';
            Shtml += '</tr>';
            Shtml += '</tbody>';
            Shtml += '</table>';
            Shtml += '</div>';
            return Shtml;
        },

        // 获取所有标点位置处于中心的点
        getPosCenter(list) {
            let left = 0; let right = 0; let top = 0; let bottom = 0;
            if (list.length > 0) {
                list.forEach(item => {
                    if (!left) {
                        left = right = item.x_coordinate_point;
                    }
                    if (!top) {
                        top = bottom = item.y_coordinate_point;
                    }
                    if (item.x_coordinate_point && item.x_coordinate_point < left) {
                        left = item.x_coordinate_point;
                    }
                    if (item.x_coordinate_point && item.x_coordinate_point > right) {
                        right = item.x_coordinate_point;
                    }
                    if (item.y_coordinate_point && item.y_coordinate_point < top) {
                        top = item.y_coordinate_point;
                    }
                    if (item.y_coordinate_point && item.y_coordinate_point > bottom) {
                        bottom = item.y_coordinate_point;
                    }
                });
            }
            const centerx = Number(left) + (Number(right) - Number(left)) / 2;
            const centery = Number(top) + (Number(bottom) - Number(top)) / 2;
            return {
                min: [left, top],
                max: [right, bottom],
                center: [centerx, centery],
            };
        },
        // 根据经纬极值计算绽放级别。
        // eslint-disable-next-line max-params
        getZoom(minLat, minLng, maxLat, maxLng) {
            // eslint-disable-next-line max-len
            const zoom = [1, 20, 50, 75, 100, 150, 250, 375, 500, 600, 700, 1000, 1200, 1400, 1600, 2000, 3000, 4200, 5500];// 级别18到3。
            const distance = this.getDistance(minLng, minLat, maxLng, maxLat).toFixed(1); // 获取两点距离,保留小数点后两位
            for (let i = 0, zoomLen = zoom.length; i < zoomLen; i++) {
                if (zoom[i] - distance > 0) {
                    // return 18 - i + 3;// 之所以会多3，是因为地图范围常常是比例尺距离的10倍以上。所以级别会增加3。
                    return 18 - i - 1; //
                }
            }
            return 1;
        },
        // 经纬度转换成三角函数中度分表形式。
        rad(d) {
            return d * Math.PI / 180.0;
        },
        // 根据经纬度计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
        // eslint-disable-next-line max-params
        getDistance(lat1, lng1, lat2, lng2) {

            const radLat1 = this.rad(lat1);
            const radLat2 = this.rad(lat2);
            const a = radLat1 - radLat2;
            const b = this.rad(lng1) - this.rad(lng2);
            let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2)
                + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
            s = s * 6378.137; // EARTH_RADIUS;
            s = Math.round(s * 10000) / 10000; // 输出为公里

            const distance = s;
            // let distance_str = '';

            // // eslint-disable-next-line radix
            // if (parseInt(distance) >= 1) {
            //     distance_str = distance.toFixed(1) + 'km';
            // } else {
            //     distance_str = distance * 1000 + 'm';
            // }
            // s=s.toFixed(4);
            return s;
        },
    },
};
</script>
<style lang="stylus" scoped></style>
